<template>
  <div>
    <el-breadcrumb class="Bdh" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>内容管理</el-breadcrumb-item>
      <el-breadcrumb-item>小视频</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="add">
      <el-button @click="toDetail('new', '')" size="small">添加</el-button>
    </div>
    <el-table :data="list" border style="width: 100%">
      <el-table-column label="编号" prop="id" width="80"></el-table-column>
      <el-table-column prop="id" v-if="false"></el-table-column>
      <el-table-column prop="title" label="标题"></el-table-column>
      <el-table-column prop="view_count" label="阅读数" width="100"></el-table-column>
      <el-table-column prop="comment_count" label="评论数" width="100"></el-table-column>
      <el-table-column prop="like_count" label="点赞数" width="100"></el-table-column>
      <el-table-column width="200" fixed="right">
        <template slot-scope="scope">
          <div class="opt">
            <p>
              <el-button size="mini" type="primary" icon="el-icon-edit" @click="toDetail('bj', scope.row)">编辑 </el-button>
              <el-button size="mini" type="danger" icon="el-icon-delete" @click="del(scope.row, scope.$index)">删除 </el-button>
            </p>
            <p>
              <el-button size="mini" type="warning" icon="el-icon-view" @click="lookcomment(scope.row)">查看评论</el-button>
            </p>
            <p>
              <el-button size="mini" type="success" icon="el-icon-sort" @click="importvideo(scope.row)">导入到视频管理</el-button>
            </p>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <p class="paging">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="current" :page-sizes="pageSizes" :page-size="PageSize" layout="total, sizes, prev, pager, next, jumper" :total="totalCount" :hide-on-single-page="value"></el-pagination>
    </p>
    <el-dialog title="查看评论" :visible.sync="comment" width="80%" @close="
				totalCount1 = 0;
				commentlist = [];
			">
      <el-table :data="commentlist" border style="width: 100%">
        <el-table-column prop="id" v-if="false"></el-table-column>
        <el-table-column prop="author_name" label="姓名" width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.author_name == '' || scope.row.author_name == null">匿名用户{{ scope.row.id }}</span>
            <span v-else>{{ scope.row.author_name }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="tel" label="电话" width="120"></el-table-column>
        <el-table-column prop="comment" label="内容"></el-table-column>
        <el-table-column width="100">
          <template slot-scope="scope">
            <div class="opt">
              <el-button size="mini" type="danger" icon="el-icon-delete" @click="delcomment(scope.row, scope.$index)">删除 </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <p class="paging">
        <el-pagination @size-change="handleSizeChange1" @current-change="handleCurrentChange1" :current-page="current1" :page-sizes="pageSizes1" :page-size="PageSize1" layout="total, sizes, prev, pager, next, jumper" :total="totalCount1" :hide-on-single-page="value1">
        </el-pagination>
      </p>
    </el-dialog>
    <el-dialog title="绑定医生" :visible.sync="binddoc" width="50%">
      <div style="text-align:left;margin-bottom:20px;width:100%;">
        填写用户编号：
        <el-input style="width: 200px;" v-model="cust_id" placeholder="输入用户编号"></el-input>
      </div>
      <div style="text-align:left;margin-bottom:20px;width:100%;">
        选择分类：
        <el-select filterable v-model="videosort" clearable placeholder="请选择分类" style="width: 200px;">
          <el-option v-for="item in sortoptions" :key="item.id" :label="item.name" :value="item.id"> </el-option>
        </el-select>
      </div>
      <div style="text-align:left;width:100%;">
        选择疾病：
        <el-select filterable v-model="ill_lib_id" clearable value-key="id" placeholder="请选择疾病" style="width: 200px;" @change="selectdistributedp">
          <el-option v-for="item in illoption" :key="item.id" :label="item.name" :value="item"> </el-option>
        </el-select>
      </div>
      <p style="text-align:left;" v-if="showdoc">由于该视频没有绑定医生，请绑定医生之后再导入。</p>
      <div v-if="showdoc" style="width:100%;overflow:hidden;">
        <el-input placeholder="请输入精确的姓名或电话号" clearable prefix-icon="el-icon-search" v-model="search" style="width: 30%;float: left;" @clear="clearsearch"> </el-input>
        <el-button type="primary" style="margin-left:20px;float: left;" @click="query">查询</el-button>
        <div v-if="doclist.length != ''" style="width: 100%;float: left;text-align: left;margin-top: 10px;">
          <el-checkbox v-model="docradio" :true-label="doclist.id">{{ doclist.name }}</el-checkbox>
        </div>
      </div>
      <div style="margin-top:10px;">
        <el-button @click="importvideobinddoc">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [],
      current: 1, //默认显示第几页
      totalCount: 0, // 总条数，根据接口获取数据长度
      pageSizes: [20], // 个数选择器（可修改）
      PageSize: 20, // 默认每页显示的条数（可修改）
      value: false,
      comment: false,
      commentlist: [],
      current1: 1, //默认显示第几页
      totalCount1: 0, // 总条数，根据接口获取数据长度
      pageSizes1: [20], // 个数选择器（可修改）
      PageSize1: 20, // 默认每页显示的条数（可修改）
      value1: false,
      rowid: "",
      binddoc: false,
      search: "",
      docradio: "",
      videoitem: "",
      doclist: [],
      videosort: "",
      sortoptions: [
        { id: 1, name: "反馈视频" },
        { id: 2, name: "寻医视频" },
      ],
      showdoc: false,
      cust_id: "",
      ill_lib_id: "",
      illoption: [],
      dp: "",
      ill_name: "",
    };
  },
  mounted() {
    this.getdata(1, this.PageSize);
  },
  methods: {
    toDetail(type, item) {
      if (type == "new") {
        this.$router.push({
          path: "/smallVideo/smallVideoDetial",
          query: { type: type },
        });
      } else {
        this.$router.push({
          path: "/smallVideo/smallVideoDetial",
          query: { type: type, id: item.id },
        });
      }
    },
    handleSizeChange() {},
    handleCurrentChange(currentPage) {
      this.getdata(currentPage, this.PageSize);
    },
    handleSizeChange1() {},
    handleCurrentChange1(currentPage) {
      this.getcomment(currentPage);
    },
    getdata(page, size) {
      this.list = [];
      this.axios
        .get("/video/get_gu_videos?page=" + page + "&size=" + size)
        .then((rs) => {
          if (rs.data.code == 0) {
            // console.log(rs);
            this.list = rs.data.result;
            this.totalCount = rs.data.count;
          } else {
            console.log(rs);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    del(row, index) {
      //删除
      // console.log(row);
      this.$confirm("此操作将永久删除该视频, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.axios
            .post("/video/delete_gu_video", this.qs.stringify({ id: row.id }))
            .then((rs) => {
              if (rs.data.code == 0) {
                this.$message({
                  message: "删除成功",
                  type: "success",
                });
                this.list.splice(index, 1);
              } else {
                this.$message(rs.data.msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    lookcomment(row) {
      this.commentlist = [];
      this.comment = true;
      this.rowid = row.id;
      this.totalCount1 = 0;
      this.getcomment(1);
    },
    getcomment(page) {
      this.axios
        .get(
          "/video/get_gu_video_comment?video_id=" +
            this.rowid +
            "&page=" +
            page +
            "&size=" +
            this.PageSize1
        )
        .then((rs) => {
          if (rs.data.code == 0) {
            this.totalCount1 = rs.data.count;
            this.commentlist = rs.data.result;
          } else {
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    delcomment(row, index) {
      this.$confirm("此操作将永久删除该评论, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.axios
            .post(
              "/video/delete_gu_video_comment",
              this.qs.stringify({ id: row.id, video_id: this.rowid })
            )
            .then((rs) => {
              if (rs.data.code == 0) {
                this.$message({
                  message: "删除成功",
                  type: "success",
                });
                this.commentlist.splice(index, 1);
                this.totalCount1--;
              } else {
                this.$message(rs.data.msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    clearsearch() {
      this.docradio = false;
      this.doclist = [];
    },
    query() {
      this.axios
        .post(
          "/gu/search_gu_docs",
          this.qs.stringify({ keyword: this.search, is_flag: "all" })
        )
        .then((rs) => {
          if (rs.data.code == 0) {
            this.doclist = rs.data.result[0];
          } else {
            this.$message({
              type: "error",
              message: "没有搜索到该医生",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    importvideo(item) {
      this.videoitem = item;
      this.binddoc = true;
      if (
        item.doc_id == 0 ||
        item.doc_id == null ||
        item.doc_id == undefined ||
        item.doc_id == "null"
      ) {
        this.showdoc = true;
      } else {
        this.showdoc = false;
      }
      this.getilllib();
    },
    getilllib() {
      this.axios.get("/gu/get_gu_ill_lib").then((rs) => {
        if (rs.data.code == 0) {
          this.illoption = rs.data.result;
        }
      });
    },
    selectdistributedp(val) {
      console.log(val);
      this.ill_lib_id = val.name;
      this.dp = val.id;
      this.ill_name = val.name;
    },
    importvideobinddoc() {
      if (this.docradio == "" && this.showdoc == true) {
        this.$message({
          type: "error",
          message: "请勾选医生",
        });
        return;
      }
      if (this.videosort == "") {
        this.$message({
          type: "error",
          message: "请选择分类",
        });
        return;
      }
      if (this.cust_id == "") {
        this.$message({
          type: "error",
          message: "请填写用户编号",
        });
        return;
      }
      if (this.dp == "") {
        this.$message({
          type: "error",
          message: "请选择疾病",
        });
        return;
      }
      var form = {
        title: this.videoitem.title,
        pic: this.videoitem.video_pic,
        type: this.videosort,
        content_type: "2",
        ill_lib_id: this.dp,
        name: "",
        sex: "",
        age: "",
        headimg: "",
        content: this.videoitem.url,
        des: this.videoitem.des,
        x_cust_id: this.cust_id,
      };
      if (this.showdoc) {
        form.doc_id = this.docradio;
      } else {
        form.doc_id = this.videoitem.doc_id;
      }
      // this.binddoc = false;
      // this.inert(form);
      this.axios
        .post(
          "/tag/check_gu_tag_by_illlibid",
          this.qs.stringify({
            ill_lib_id: this.dp,
            ill_lib_name: this.ill_name,
          })
        )
        .then((res) => {
          if (res.data.code == 0) {
            this.binddoc = false;
            this.inert(form);
          }
        });
    },
    inert(form) {
      this.axios
        .post("/item/insert_gu_item", this.qs.stringify(form))
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({
              type: "success",
              message: "添加成功",
            });
            this.bangdingjibing(this.dp,res.data.id);
            // this.$router.push("/userupload");
          } else {
            this.$message({
              type: "error",
              message: res.data.msg,
            });
          }
        });
    },
    bangdingjibing(illid, _id) {
      this.axios
        .post(
          "/item/update_gu_item_illlibid",
          this.qs.stringify({ ill_lib_id: illid, id: _id })
        )
        .then((res) => {
          if (res.data.code == 0) {
            console.log("绑定成功");
          }
        });
    },
  },
};
</script>
<style>
.opt a {
  cursor: pointer;
  color: green;
  margin-right: 20px;
  display: inline-block;
  margin-left: 0;
}
.opt p {
  margin: 0 0 8px 0;
}
.add {
  width: 100%;
  height: 50px;
  text-align: left;
}

.Bdh {
  height: 50px;
  width: 100%;
  line-height: 40px;
}
</style>
